.Containerh1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.divSvg{
  width: 100px;
}

.Svg{
  height: auto;
  width: 100%;
}
// .pm {
//   content: "";
//   background-color: var(--bleu);
//   width: 80px;
//   height: 5px;
//   clip-path: polygon(4% 0, 100% 0, 96% 100%, 0% 100%);
//   display:flex;
// }
