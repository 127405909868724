:root {
  --bleu: #00d9f2;
  --noir: #000000;
  --blanc: #ffffff;
  --gris-secondaire: #f1f1f1;
  --gris-primaire: #fafafa;

  // ici les couleurs d'accent (ex: form est bien complèter)
  --green: #02b80b;
  --red:#da0000;
  --orange: #ff9100;

  --font-weight-thin: 100;
  --font-weight-extralight: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;

  --font-size-extra-small: 0.9rem;
  --font-size-small: 1rem;
  --font-size-medium: 1.1rem;
  --font-size-large: 1.5rem;
  --font-size-extra-large: 2rem;
  
}
