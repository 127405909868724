.allBloc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bloc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 25px;
  padding-bottom: 15px;
  background-color: var(--gris-secondaire);
  min-height: auto;
  // height: 600px;
  width: 360px;
  border-radius: 6px;
  border-bottom: 2px solid var(--bleu);
}

.blocTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-extra-small);
  font-weight: var(--font-weight-medium);
}

.logo {
  padding-right: 1rem;
}

.logoSocial {
  width: 50px;
  margin-right: 10px;
}

.logoSocialDernier{
  width: 50px;
  margin-right: 0px;
}

.blocLogoSocial {
  display: flex;
  justify-content: center;
  align-items: center;
}

.allBlocListe {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blocListe {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.itemListe {
  padding-bottom: 10px;
  font-size: var(--font-size-extra-small);
  font-weight: var(--font-weight-regular);
}

.itemListeText {
  font-size: var(--font-size-extra-small);
  font-weight: var(--font-weight-medium);
  padding-left: 5px;
}

.textSocial{
  font-size: var(--font-size-extra-small);
  font-weight: var(--font-weight-regular);
}

@media screen and (min-width: 1024px) {
  .bloc {
    width: 500px;
  }
}
