.allBloc{
    margin-left: 1rem;
    margin-right: 1rem;
}

.blocTitle{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.title{
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-semibold);
}

.pm {
  content: "";
  background-color: var(--bleu);
  width: 80px;
  height: 5px;
  clip-path: polygon(4% 0, 100% 0, 96% 100%, 0% 100%);
  display:flex;
}

.text{
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-regular);
    text-align: justify;
}

.links{
    // color: var(--bleu);
    font-weight: var(--font-weight-medium);
    margin-left: 5px;
}