@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;1,300;1,400&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

*, ::after, ::before{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  padding-left: 0px;
  margin: 0;
}

img {
  max-width: 100%;
}

input {
  font-family: 'Poppins', sans-serif;
}

::placeholder {
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
  color: var(--noir);
}