.Container1 {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  min-height: 70vh;
  width: 100%;
  position: relative;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-large);
  background: linear-gradient(#00000048, #00000023);
  padding-top: 50px;

  flex-wrap: wrap;

  &::before {
    content: "";
    background: url("../../../public/images/backgroundHomePage.jpg") no-repeat
      center/cover;
    opacity: 0.9;
    z-index: -1;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.blocText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 25px;
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  // font-family: 'Lato', sans-serif;
  font-size: var(--font-size-extra-large);
  font-weight: var(--font-weight-semibold);
  text-shadow: 1px 1px 0px var(--bleu);
  color: var(--blanc);
}

.blocParagraph {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 20px;
  background-color: #000000ab;
  border-radius: 6px;
}

.paragraph {
  font-size: var(--font-size-extra-small);
  font-weight: var(--font-weight-light);
  color: var(--blanc);
  margin-bottom: 10px;
  display: flex;
  width: 100%;
}

.bouton {
  display: flex;
  justify-content: center;
  align-items: center;

  &_margin {
    margin-top: 25px;
    margin-bottom: 50px;
  }
}

.Container2{
  margin-top: 25px;
}

.subtitle {
  font-size: var(--font-size-extra-large);
  text-align: center;
  // font-family: 'Lato', sans-serif;
  font-weight: var(--font-weight-semibold);
}

.Container_bloc {
  // width: 76%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .Container_bloc {
    width: 40%;
  }
  .blocParagraph {
  width: 650px;
}
}
