/* Dans votre fichier CSS */
.carouselContainer {
    width: 80%;
    margin: 0 auto;
    text-align: center;

    li {
        > div {
            img {
                height: 100%;
                width: auto;
            }
        }
    }
}