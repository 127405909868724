@use "sass:math";

$burger-margin-lines: .25rem;
$burger-thickness: .4rem;
$burger-aspect-ratio: math.div(13, 2);
$burger-animation-speed: .15s;

header {
    background-color: hsla(0, 0%, 100%, 75%);
    height: 10vh;
    width: 100vw;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    transition: background-color 100ms ease-in-out;
    

    > * {
        margin-inline: 3%;
    }
}

.container {
    margin-right: 5%;
}


.burgerContainer {
    z-index: 10;
    display: flex;
    align-items: flex-end;
}

.burgerBtncontainer {
    height: 100%;
    aspect-ratio: 13 / 2;

    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .line {
        height: $burger-thickness;
        aspect-ratio: $burger-aspect-ratio;
        background-color: var(--bleu);
        border-radius: 10px;
        transform-origin: center;
        transition: opacity $burger-animation-speed ease-in-out,
            transform $burger-animation-speed ease-in-out;

        &:nth-child(2) {
            margin: $burger-margin-lines 0;
        }
    }

    #check {
        display: none;

        &:checked~.lines {
            &> :nth-child(1) {
                transform: translateY(calc($burger-margin-lines + $burger-thickness)) rotate(45deg);
            }

            &> :nth-child(2) {
                transform: translateX(5rem);
                opacity: 0;
            }

            &> :nth-child(3) {
                transform: translateY(calc(-#{$burger-margin-lines} - #{$burger-thickness})) rotate(-45deg);
            }
        }
    }
}

.openedHeader {
    background-color: var(--gris-secondaire) !important;
}