.bloc {
  //display: flex;
  //justify-content: space-between;
  color: black;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    margin-block: 1em;
  }
}

.bloc2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.img {
  margin-inline-start: 3%;
  margin-inline-end: 3%;
}
.p {
  text-align: right;
  margin-right: 3%;
  font-size: var(--font-size-extra-small);
}

.links {
  text-align: center;
  font-size: var(--font-size-extra-small);
}

@media screen and (min-width: 768px) {
  .bloc {
    height: 80px;
    align-items: center;
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
  }
}
