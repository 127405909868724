.bloc {
  display: inline-flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border-bottom: 5px solid var(--bleu, #00d9f2);
  background: var(--gris-primaire, #fafafa);
  transition: all 0.2s ease-in-out;
  color: var(--noir);
  cursor: pointer;
  &:hover {
    border-top: 5px solid var(--bleu, #00d9f2);
    border-bottom: 0px solid var(--bleu, #00d9f2);
  }
}

.textBloc{
    text-decoration: none;
}

.text {
  color: var(--noir);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-medium);
}
