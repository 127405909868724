.Container_bloc_photo {
  border-radius: 12px;
  background-color: var(--gris-secondaire);
  border-bottom: 2px solid var(--bleu);
  margin-top: 20px;
  overflow: hidden;
  padding-top: 15px;
  padding-bottom: 20px;
}
.Container_photo_portfolio {
  padding-top: 0px;
}

.text {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  padding-left: 7px;
  padding-bottom: 7px;
}
.images {
  width: 300px;
  border-radius: 6px;
  margin-right: 20px;
  margin-left: 20px;
}

.Container_photo_portfolio {
  display: flex;
}

.Content {
  border: 1px rgba(255, 0, 0, 0) solid;
}
@media screen and (min-width: 768px) {
  .text {
  font-size: var(--font-size-medium);
  }
}
