.container {
  position: fixed;
  background-color: var(--gris-secondaire);
  width: 100%;
  height: 90vh;
  margin-top: 10vh;
  background-image: url("../../../public/images/backgroundHomePage.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  nav {
    height: 100%;
    width: 100%;
    padding: 20% 0;
  }

  ul {
    padding: 0;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    li {
      font-size: var(--font-size-extra-large);
      font-weight:var(--font-weight-semibold);
    }
  }
}
