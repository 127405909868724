*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // outline: 1px solid black;
}

#a {
  min-height: 100vh;
}


  li.slide {
      > div {
          height: 100%;
          img {
              object-fit: contain;
          }
      }
  }
body{
  background-color: var(--gris-primaire);
}

@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) {
  body {
    font-size: 18px;
  }
}