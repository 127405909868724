.allBloc {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #222222;
}

.bloc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--gris-secondaire);
  padding: 30px;
  border-radius: 6px;
  border-bottom: 5px solid var(--bleu);
}

.blocTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  padding-right: 10px;
}

.item {
  padding-bottom: 10px;
  &:last-child {
    padding-bottom: 0;
    padding-top: 10px;
  }
}
