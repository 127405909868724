.Container_bloc {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Container_bloc_photo {
  border-radius: 12%;
  padding-top: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Container_photo_portfolio {
  padding-top: 25px;
}

@media screen and (min-width: 768px) {
  .Container_bloc {
    width: 40%;
  }
}

