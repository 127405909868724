.blocContactForm {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:only-child {
    padding-bottom: 5px;
  }
}

.blocForm {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.blocFormTitle {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semibold);
  display: flex;justify-content: center;align-items: center;
}


@media screen and (min-width : 1024px){
    .blocContactForm{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .blocForm{
        padding-top: 0px;
    }
}